import PropTypes from 'prop-types'
import classNames from 'classnames'
import { formatShopifyPrice } from '@/utils/formatPrice'
import FreeShippingProgressBar from '@/components/FreeShippingProgressBar'

const OrderSummaryItem = ({
  title,
  amount,
  lines,
  emptyAmountDisplay,
  isTotal,
  isShippingLine,
  cartTotal,
  shippingThreshold,
  hasFreeShippingDiscount,
  isExpeditedShipping,
}) => {
  function renderAmountDisplay(amount) {
    if (amount > 0) {
      return <h6>${formatShopifyPrice(amount, true)}</h6>
    } else if (emptyAmountDisplay) {
      return (
        <div className="order-summary__item-amount zero">
          {emptyAmountDisplay}
        </div>
      )
    } else {
      return <></>
    }
  }

  return (
    <div
      className={classNames(
        'order-summary__item',
        lines ? 'order-summary__item--removable' : '',
      )}
    >
      {isTotal ? (
        <h3 className="order-summary__item">{title}</h3>
      ) : (
        <h6>{title}</h6>
      )}
      {isShippingLine && (
        <FreeShippingProgressBar
          total={cartTotal}
          shippingThreshold={shippingThreshold}
          hasFreeShippingDiscount={hasFreeShippingDiscount}
          isCheckout={true}
          isExpeditedShipping={isExpeditedShipping}
        />
      )}
      {lines ? <div>{lines}</div> : renderAmountDisplay(amount)}
    </div>
  )
}

OrderSummaryItem.propTypes = {
  title: PropTypes.string,
  amount: PropTypes.number,
  lines: PropTypes.node,
}

export default OrderSummaryItem
