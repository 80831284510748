import ResponsiveImage from '@/components/ResponsiveImage'
import Link from 'next/link'
import { memo } from 'react'

const lineItemImgLoader = ({ src }) => {
  return `${src}?w=400`
}

const LineItemProductImage = ({ imageUrl, title, productUrl, isGiftOrder }) => {
  return (
    <Link className="order-item__image" href={productUrl}>
      <ResponsiveImage
        loader={lineItemImgLoader}
        src={imageUrl}
        alt={title}
        isGiftOrder={isGiftOrder}
      />
    </Link>
  )
}

export default memo(LineItemProductImage)
