const RedactedCreditCard = ({ brand, lastFourDigits }) => (
  <span className="redacted-card">
    {brand && <span className="redacted-card__brand">{brand}</span>}
    <span className="redacted-card__number">
      {' '}
      {lastFourDigits.length <= 4 && (
        <>
          &bull;&bull;&bull;&bull;
          {' '}
          &bull;&bull;&bull;&bull;
          {' '}
          &bull;&bull;&bull;&bull;
          {' '}
        </>
      )}
      {lastFourDigits}
    </span>
  </span>
);

export default RedactedCreditCard;
