import IconPlus from '@/svgs/plus.svg'
import IconMinus from '@/svgs/minus.svg'
import IconTrashCan from '@/svgs/trash-can.svg'
import { useHeadlessCheckoutContext } from '@/context/HeadlessCheckoutContext'
import { dataLayerATC, dataLayerRFC } from '@/utils/dataLayer'
import { memo } from 'react'

const QuantitySelector = ({
  item,
  allowQuantityAdjustment,
  allowDeletion,
  isPrepaidEarlyBird,
  isPrepaidShipments,
}) => {
  const { updateLineItem, removeLineItem } = useHeadlessCheckoutContext()

  if (allowQuantityAdjustment && !isPrepaidEarlyBird && !isPrepaidShipments) {
    const increment = () => {
      dataLayerATC({ item: { ...item, quantity: item.quantity + 1 || 1 } })
      updateLineItem({
        ...item,
        quantity: item.quantity + 1,
      })
    }

    const decrement = () => {
      dataLayerRFC({ item: { ...item, quantity: item.quantity - 1 || 0 } })
      if (item.quantity <= 1) {
        removeLineItem(item)
      } else {
        updateLineItem({
          ...item,
          quantity: item.quantity - 1,
        })
      }
    }

    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div className="order-item__quantity-wrapper">
          <button
            data-testid={'line-item-product-minus-button'}
            onClick={() => decrement()}
            className="order-item__decrement-btn"
          >
            <IconMinus />
          </button>
          <div
            data-testid={'line-item-product-quantity-display'}
            className="order-item__quantity"
            aria-label="product quantity"
          >
            {item.quantity}
          </div>
          <button
            data-testid={'line-item-product-plus-button'}
            onClick={() => increment()}
            className="order-item__increment-btn"
          >
            <IconPlus />
          </button>
        </div>
        <button
          data-testid={'line-item-product-trash-button'}
          onClick={() => removeLineItem(item)}
          className="order-item__remove-btn"
        >
          <IconTrashCan />
        </button>
      </div>
    )
  } else {
    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div className="order-item__quantity-wrapper order-item__quantity-wrapper--quantity-only">
          <div
            data-testid={'line-item-product-quantity-display'}
            className="order-item__quantity"
            aria-label="product quantity"
          >
            {item.quantity}
            {(isPrepaidEarlyBird || isPrepaidShipments) && <> Shipments</>}
          </div>
        </div>
        {allowDeletion && (
          <button
            data-testid={'line-item-product-trash-button'}
            onClick={() => removeLineItem(item)}
            className="order-item__remove-btn"
          >
            <IconTrashCan />
          </button>
        )}
      </div>
    )
  }
}

export default memo(QuantitySelector)
