import { memo } from 'react'
import { formatShopifyPrice } from '@/utils/formatPrice'

const DiscountPill = ({ discountedAmount, code: unformattedCode }) => {
  let code = unformattedCode
  if (code.length > 10) {
    code = code.slice(0, 10) + '...'
  }
  return <div className="discount-pill">{`${code} ${discountedAmount}`}</div>
}

const DiscountPills = ({ discounts, sellingPlanAllocation }) => {
  const hasDiscount = discounts && discounts.length > 0
  const hasSubscriptionDiscount =
    sellingPlanAllocation?.sellingPlan.priceAdjustments.length > 0

  if (hasDiscount || hasSubscriptionDiscount)
    return (
      <div className="order-item__discounts">
        {discounts.map((discount, i) => {
          const discountedAmount =
            discount.discountedAmount?.amount || // cart
            discount.allocatedAmountSet.presentmentMoney.amount // post-checkout
          const code = discount.discountApplication
            ? discount.discountApplication.code ||
              discount.discountApplication.title // post-checkout
            : discount.code || discount.title // cart
          return (
            <DiscountPill
              key={`${code}-discount-${i}`}
              discountedAmount={`(-${formatShopifyPrice(discountedAmount)})`}
              code={code}
            />
          )
        })}
        {sellingPlanAllocation?.sellingPlan.priceAdjustments.map((adj, i) => (
          <DiscountPill
            key={`${sellingPlanAllocation.sellingPlan.name}-adjustment-${i}`}
            discountedAmount={`-${adj.adjustmentValue.adjustmentPercentage}%`}
            code={sellingPlanAllocation.sellingPlan.name}
          />
        ))}
      </div>
    )
}

export default memo(DiscountPills)
