// import { useModalContext } from '@/context/ModalContext'
import { memo } from 'react'
import { formatShopifyPrice } from '@/utils/formatPrice'
import DiscountPills from './DiscountPills'

const LineItemPrice = ({
  discounts,
  item,
  cartContainsPrepaid,
  isSubscription,
  isPrepaidShipments,
  // displayLoginPrompt,
}) => {
  // const modalContext = useModalContext()

  const hasDiscount = discounts && discounts.length > 0
  const totalDiscountAmount = discounts
    .map(
      (d) =>
        d.discountedAmount?.amount || // cart
        d.allocatedAmountSet.presentmentMoney.amount, // post-checkout
    )
    .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
  const originalPricePerQuantity =
    item.cost?.amountPerQuantity.amount ||
    item.originalUnitPriceSet?.presentmentMoney.amount
  const discountedPricePerQuantity = formatShopifyPrice(
    (originalPricePerQuantity * item.quantity - totalDiscountAmount) /
      item.quantity,
  )
  const discountedPrice = formatShopifyPrice(
    originalPricePerQuantity * item.quantity - totalDiscountAmount,
  )
  const originalPrice = formatShopifyPrice(
    (item.cost?.compareAtAmountPerQuantity?.amount
      ? item.cost.compareAtAmountPerQuantity.amount * item.quantity
      : undefined) || // cart -- (this is different than the subtotal amount when it is there)
      item.cost?.subtotalAmount?.amount || // cart
      item.originalTotalSet?.presentmentMoney.amount, // post-checkout
  )

  // function openLoginModal(e) {
  //   e.preventDefault()
  //   modalContext.setIsOpen(false)
  //   modalContext.setModalType('login-noredirect')
  //   modalContext.setIsOpen(true)
  // }

  // const loginPrompt = (
  //   <p data-testid="discount-price" className="price-login-prompt">
  //     <a onClick={openLoginModal}>
  //       Log in for <b>${formatShopifyPrice(item.variant.price.amount * 0.9)}</b>{' '}
  //       with 10% Member Discount
  //     </a>
  //   </p>
  // )

  return (
    <div className="order-item__price">
      <div>
        {(hasDiscount ||
          (originalPrice && originalPrice > discountedPrice)) && (
          <p className="original-price">{`$${originalPrice}`}</p>
        )}
        <p className="price">{`$${discountedPrice}`}</p>
      </div>
      {(isSubscription && !cartContainsPrepaid) ||
        (isPrepaidShipments && (
          <p className="price-per-unit">{`($${discountedPricePerQuantity}/box)`}</p>
        ))}
      <DiscountPills
        discounts={discounts}
        sellingPlanAllocation={item.sellingPlanAllocation}
      />
      {/* {displayLoginPrompt && loginPrompt} */}
    </div>
  )
}

export default memo(LineItemPrice)
