import { RemoveIcon } from '../../Icons';
import { formatShopifyPrice } from '@/utils/formatPrice';

const OrderSummaryItemLine = ({ description, amount, onRemove, readOnly }) => {
  return (
    <div className='order-summary-item__wrapper'>
      <div className="order-summary-item__description">
        {onRemove && !readOnly && (
          <button
            className="order-summary-item__action"
            type="button"
            onClick={onRemove}
          >
            <RemoveIcon />
          </button>
        )}
        <span className="order-summary-item__label">{description}</span>
      </div>
      {
        amount && <div className="order-summary-item__amount">
          <p>${formatShopifyPrice(amount, true)}</p>
        </div>
      }
    </div>
  );
};

export default OrderSummaryItemLine;
